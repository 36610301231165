import React from 'react'
import { withRouter } from 'react-router-dom'
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Tag
} from 'antd'
import _get from 'lodash/get'
import _debounce from 'lodash/debounce'
import _unionWith from 'lodash/unionWith'
import _isEqual from 'lodash/isEqual'
import moment from 'moment'
import Upload from '../Upload'
import ProvinceSelectList from '../ProvinceSelectList'
import {
  ProjectAPI,
  ProvincesAPI,
  DistrictsAPI,
  SubdistrictsAPI
} from '../../services'
const { Option } = Select
class AdsForm extends React.Component {
  state = {
    allProjectsList: [],
    projectsList: [],
    provincesList: [],
    districtsList: [],
    subdistrictsList: [],
    weeks: 0
  };

  componentDidMount () {
    this.fetch()
  }

  fetch = async () => {
    const provincesList = await ProvincesAPI.find()
    if (this.props.isEdit) {
      this.searchProject(this.props.data.project.title.title_th)
    }
    this.setState({
      provincesList
    })
  }

  idProject =async (value) => {
    const fill = {}
    fill.id = value

    if (value !== '') {
      const projectsList = await ProjectAPI.find({
        filter: {
          where: fill, limit: 10
        }
      })
      this.props.form.setFieldsValue({ link_out: projectsList[0].website })
    } else {
      this.props.form.setFieldsValue({ link_out: undefined })
    }
  }

  searchProject = async (value) => {
    const projectsList = await ProjectAPI.find({
      filter: {
        where: {
          title_th: { regexp: `/.*${value}.*/i` }
        },
        limit: 10
      }
    })
    const { allProjectsList } = this.state
    const result = _unionWith(allProjectsList, projectsList, _isEqual)
    this.setState({
      projectsList,
      allProjectsList: result
    })
  }

  searchSuccess = e => {
    this.idProject(e.key)
  }

  genSlug = (text = '') => {
    const specialChar = [';', ':', '!', '*', '"', "'", '-', '/', '?', '“', '&', '[', ']', '(', ')', '{', '}']
    try {
      return text.split('').filter(i => !specialChar.includes(i)).join('').replace(/\s/gi, '-')
        .toLocaleLowerCase()
    } catch (error) {
      return null
    }
  }

  formatData = (values) => {
    const { allProjectsList = [], provincesList = [], allDistrictsList = [], allSubdistrictsList = [] } = this.state
    const pj = _get(values, 'project', []) || []
    const projectsSelected = allProjectsList.filter((p) => {
      if (p.id === pj.key) {
        return p.id === pj.key
      }
    }) || []
    const data = {
      project: {
        id: _get(projectsSelected, '[0].id'),
        title: {
          title_th: _get(projectsSelected, '[0].title_th'),
          title_en: _get(projectsSelected, '[0].title_en')
        },
        alias: {
          alias_th: `${this.genSlug(_get(projectsSelected, '[0].title_th'))}-`,
          alias_en: `${this.genSlug(_get(projectsSelected, '[0].title_en'))}-`
        }
      },
      title: _get(values, 'title'),
      position: _get(values, 'position'),
      date_start: (_get(values, 'date_start')),
      date_end: (_get(values, 'date_end')),
      link_out: _get(values, 'link_out'),
      province: _get(values, 'address', []).map(address => {
        const province = provincesList.find(p => p.province_code === address.province_id)
        const district = allDistrictsList.find(d => d.district_code === address.district_id)
        const subdistrict = allSubdistrictsList.find(s => s.sub_district_code === address.subdistrict_id)
        return {
          district_id: _get(address, 'district_id'),
          district_th: _get(district, 'name_th'),
          district_en: _get(district, 'name_en'),
          province_id: _get(address, 'province_id'),
          province_th: _get(province, 'name_th'),
          province_en: _get(province, 'name_en'),
          subdistrict_id: _get(address, 'subdistrict_id'),
          subdistrict_th: _get(subdistrict, 'name_th'),
          subdistrict_en: _get(subdistrict, 'name_en')
        }
      }),
      detail: _get(values, 'detail'),
      banner: _get(values, 'banner', []).map(image => ({ url: image.url, width: image.width, height: image.height })),
      banner_mobile: _get(values, 'banner_mobile', []).map(image => ({ url: image.url, width: image.width, height: image.height })),
      weeks: this.calWeek(this.props.form.getFieldValue('date_start'), this.props.form.getFieldValue('date_end'))
    }
    return data
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        try {
          values.weeks = this.state.weeks

          const { id } = this.props.match.params
          const result = this.formatData(values)
          this.props.onSubmit({ data: result, id })
        } catch (error) {
          console.log('AdsForm Error', error)
        }
      }
    })
  };

  onUpload = (field, value) => {
    this.props.form.setFieldsValue({ [field]: value })
  }

  selectProvinces = async (index, provinceCode) => {
    const address = this.props.form.getFieldValue('address')
    if (!address[index]) {
      address.push({
        id: Date.now(),
        province_id: provinceCode,
        district_id: undefined,
        subdistrict_id: undefined
      })
    } else {
      address[index].province_id = provinceCode
      address[index].district_id = undefined
      address[index].subdistrict_id = undefined
    }
    this.props.form.setFieldsValue({ address })
  }

  selectDistricts = async (index, districtCode) => {
    const address = this.props.form.getFieldValue('address')
    if (address[index]) {
      address[index].district_id = districtCode
      address[index].subdistrict_id = undefined
      this.props.form.setFieldsValue({ address })
    }
  }

  selectSubdistricts = async (index, subdistrictCode) => {
    const address = this.props.form.getFieldValue('address')
    if (address[index]) {
      address[index].subdistrict_id = subdistrictCode
      this.props.form.setFieldsValue({ address })
    }
  }

  calWeek = (startDate, endDate) => {
    startDate = new Date(startDate)
    endDate = new Date(endDate)
    const millisecondsPerDay = 86400 * 1000 // Day in milliseconds
    startDate.setHours(0, 0, 0, 1) // Start just after midnight
    endDate.setHours(23, 59, 59, 999) // End just before midnight
    const diff = endDate - startDate // Milliseconds between datetime objects
    const days = Math.ceil(diff / millisecondsPerDay)
    const weeks = Math.ceil(days / 7)
    this.setState({
      weeks
    })
    return weeks
  }

  fetchProvince = async (provinceCode) => {
    if (!provinceCode) return null
    const districtsList = await DistrictsAPI.find({
      filter: { where: { province_code: provinceCode } }
    })

    const { allDistrictsList } = this.state
    const result = _unionWith(allDistrictsList, districtsList, _isEqual)
    this.setState({
      districtsList,
      allDistrictsList: result
    })
  }

  fetchDistrict = async (districtCode) => {
    if (!districtCode) return null
    const subdistrictsList = await SubdistrictsAPI.find({
      filter: { where: { district_code: districtCode } }
    })
    const { allSubdistrictsList } = this.state
    const result = _unionWith(allSubdistrictsList, subdistrictsList, _isEqual)

    this.setState({
      subdistrictsList,
      allSubdistrictsList: result
    })
  }

  render () {
    const { data } = this.props
    const { getFieldDecorator } = this.props.form
    const { TextArea } = Input
    // const { Option } = Select
    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 }
    }
    const formItem2Layout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 10 }
    }
    const formTailLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 8, offset: 4 }
    }
    const rangeConfig = {
      rules: [
        { type: 'object', required: true, message: 'Please select time!' }
      ]
    }
    const { projectsList } = this.state
    const pj = _get(data, 'project')

    return (
      <div>
        <Form onSubmit={this.handleSubmit}>
          <Form.Item {...formItemLayout} label='ชื่อแบนเนอร์'>
            {getFieldDecorator('title', {
              initialValue: _get(data, 'title'),
              rules: [{ required: true, message: 'กรุณาระบุชื่อแบนเนอร์!' }]
            })(<Input placeholder='ชื่อแบนเนอร์' />)}
          </Form.Item>
          <Form.Item {...formItemLayout} label='ตำแหน่งแบนเนอร์'>
            {getFieldDecorator('position', {
              initialValue: _get(data, 'position'),
              rules: [
                {
                  required: true,
                  message: 'กรุณาระบุตำแหน่ง แบนเนอร์'
                }
              ]
            })(
              <Select placeholder='เลือกตำแหน่งแบนเนอร์'>
                <Option value='A1'>A1: หน้าแรก ตำแหน่งบนสุด</Option>
                <Option value='A2'>A2: หน้าแรก ในกล่องโครงการแนะนำ</Option>
                <Option value='A3'>A3: Banner about service of baania</Option>
                <Option value='B1'>B1: ด้านขวา ของหน้ารายการข้อมูล</Option>
                <Option value='C1'>C1: หน้ารายละเอียดบ้าน, ในตำแหน่งด้านขวาของข้อมูลบ้าน</Option>
                <Option value='C2'>C2: หน้ารายละเอียดบ้าน, ในตำแหน่งด้านขวาของสถานที่รอบข้าง</Option>
                <Option value='C3'>C3: หน้ารายละเอียดบ้าน, ในตำแหน่งด้านล่าง Loan Calculator</Option>
                <Option value='C4'>C4: หน้ารายละเอียดบ้าน, ในตำแหน่งด้านขวาของกราฟประวัติราคา</Option>
                <Option value='D1'>D1: หน้า Baania Article, ในตำแหน่งด้านขวาของเนื้อหา</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item {...formItem2Layout} label='วันที่เริ่มแสดง'>
            {getFieldDecorator(
              'date_start',
              {
                initialValue: moment(_get(data, 'date_start'))
              }, rangeConfig
            )(<DatePicker format='YYYY-MM-DD' onChange={(e => { this.calWeek(e, this.props.form.getFieldValue('date_end')) })} />)}
          </Form.Item>

          <Form.Item {...formItem2Layout} label='ถึงวันที่'>
            {getFieldDecorator(
              'date_end',
              {
                initialValue: moment(_get(data, 'date_end'))
              }, rangeConfig
            )(<DatePicker format='YYYY-MM-DD' onChange={(e => { this.calWeek(this.props.form.getFieldValue('date_start'), e) })} />)}
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label='อ้างอิงโครงการ'
            extra='เลือกโครงการที่จะให้แสดงเมื่อคลิ๊กแบนเนอร์นี้'
          >
            {getFieldDecorator('project', { initialValue: { key: _get(pj, 'id'), label: _get(pj, 'title.title_th') } })(
              <Select
                showSearch
                placeholder='เลือกโปรเจค'
                labelInValue
                onChange={this.searchSuccess}
                onSearch={_debounce(this.searchProject, 300)}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option value=''>ไม่ระบุ</Option>
                {
                  projectsList.map(item => <Option key={item.id} value={item.id}>{item.title_th}</Option>)
                }
              </Select>
            )}
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label='ลิงค์ปลายทาง'
            extra='ถ้าไม่เลือก อ้างอิงโครงการ ต้องใส่ข้อมูล ลิงค์ปลายทางนี้'
          >
            {getFieldDecorator('link_out', {
              rules: [{ required: true, message: 'ลิงค์ปลายทาง!' }],
              initialValue: _get(data, 'link_out')
            })(<Input placeholder='ลิงค์ปลายทาง' />)}
          </Form.Item>
          <Form.Item {...formItemLayout} label='ให้แสดงเฉพาะจังหวัด'>
            {getFieldDecorator('address', {
              initialValue: _get(data, 'province', [])
            })(<ProvinceSelectList
              provincesList={this.state.provincesList}
              districtsList={this.state.districtsList}
              subdistrictsList={this.state.subdistrictsList}
              fetchProvince={this.fetchProvince}
              fetchDistrict={this.fetchDistrict}
              selectProvinces={this.selectProvinces}
              selectDistricts={this.selectDistricts}
              selectSubdistricts={this.selectSubdistricts}
               />)}
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label='รูปแบนเนอร์'
            extra='ไฟล์ต้องมีขนาดเล็กกว่า 64 MB ประเภทไฟล์ที่อนุญาติ .png .gif .jpg .jpeg'
          >
            {getFieldDecorator('banner', {
              getValueFromEvent: this.onUpload
            })(
              <Upload
                listType='picture-card'
                folderName='ads-banner'
                accept='.png, .jpg, .jpeg, gif, .svg'
                defaultFileList={_get(data, 'banner')}
                onUpload={(value) => this.onUpload('banner', value)}
              />
            )}
            <div style={{ marginBottom: -16 }}><Tag>รูปภาพที่แสดง จะใช้รูปภาพจริงที่อัพโหลดโดยไม่มีการปรับขนาด ดังนั้น ให้อัพโหลดตามตำแหน่งของแบนเนอร์ ที่เลือกดังนี้</Tag></div>
            <div style={{ marginBottom: -16 }}>
              <Tag color='cyan'>A1 : 1004x250 pixel</Tag>
            </div>
            <div style={{ marginBottom: -16 }}>
              <Tag color='cyan'>A2 : 752x196 pixel</Tag>
            </div>
            <div style={{ marginBottom: -16 }}>
              <Tag color='cyan'>B1 : 300x250 pixel</Tag>
            </div>
            <div style={{ marginBottom: -16 }}>
              <Tag color='cyan'>C1 : 300x250 pixel</Tag>
            </div>
            <div style={{ marginBottom: -16 }}>
              <Tag color='cyan'>C2 : 300x250 pixel</Tag>
            </div>
            <div style={{ marginBottom: -16 }}>
              <Tag color='cyan'>C3 : 1024x160 pixel</Tag>
            </div>
            <div style={{ marginBottom: -16 }}>
              <Tag color='cyan'>C4 : 300x250 pixel</Tag>
            </div>
            <div style={{ marginBottom: -10 }}>
              <Tag color='cyan'>D1 : 300x250 pixel</Tag>
            </div>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label='รูปแบนเนอร์ (Mobile)'
            extra='ไฟล์ต้องมีขนาดเล็กกว่า 64 MB ประเภทไฟล์ที่อนุญาติ .png .gif .jpg .jpeg'
          >
            {getFieldDecorator('banner_mobile', {
              getValueFromEvent: this.onUpload
            })(
              <Upload
                listType='picture-card'
                folderName='ads-banner'
                accept='.png, .jpg, .jpeg, gif, .svg'
                defaultFileList={_get(data, 'banner_mobile')}
                onUpload={(value) => this.onUpload('banner_mobile', value)}
              />
            )}
            <div style={{ marginBottom: -16 }}>
              <Tag color='cyan'>A1 : 512x160 pixel</Tag>
            </div>
            <div style={{ marginBottom: -10 }}>
              <Tag color='cyan'>C3 : 512x320 pixel</Tag>
            </div>
          </Form.Item>
          <Form.Item {...formItemLayout} label='รายละเอียด'>
            {getFieldDecorator('detail', {
              // rules: [{ required: true, message: "กรุณาระบุชื่อแบนเนอร์!" }]
              initialValue: _get(data, 'detail')
            })(<TextArea rows={4} placeholder='รายละเอียด' />)}
          </Form.Item>
          <Form.Item {...formTailLayout}>
            <Button type='primary' htmlType='submit' style={{ marginRight: 8 }}>
              บันทึก
            </Button>
            <Button
              type='primary'
              onClick={() => {
                this.props.history.push('/ads')
              }}
            >
              กลับ
            </Button>
          </Form.Item>
        </Form>
      </div>
    )
  }
}
const WrappedAdsCreateForm = Form.create()(AdsForm)
export default withRouter(WrappedAdsCreateForm)
