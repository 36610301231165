import Resource from '../utils/resource'

export const ArticleAPI = new Resource('/articles', {
  find: {
    url: '',
    method: 'get'
  },
  create: {
    url: '',
    method: 'post'
  },
  allredirect: {
    url: 'allredirect',
    method: 'get'
  }
})
