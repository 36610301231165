import React from 'react'
import { Button, Icon } from 'antd'
import _isEqual from 'lodash/isEqual'
import _isEmpty from 'lodash/isEmpty'

import ProvinceSelect from '../ProvinceSelect'

class ProvinceSelectList extends React.Component {
  state = {
    value: []
  }

  componentDidMount () {
    this.setState({
      value: this.props.value
    })
  }

  componentDidUpdate (prevProps, prevState) {
    if (!_isEqual(prevState.value, this.props.value)) {
      if (_isEmpty(this.props.value)) {
        return
      }
      this.setState({
        value: this.props.value
      })
    }
  }

  addMoreProvince = () => {
    const { value } = this.state
    value.push({
      id: Date.now(),
      province: undefined,
      district: undefined,
      subdistrict: undefined,
    })

    this.setState({
      value
    })
  }

  remove = (index) => {
    const { value } = this.state
    value.splice(index, 1)
    this.setState({
      value
    })
  }

  render () {
    const { selectDistricts, selectProvinces, selectSubdistricts, provincesList, districtsList, subdistrictsList, fetchProvince, fetchDistrict } = this.props
    const { value } = this.state
    return (
      <>
        {
          value.map((item, index) => (
            <div key={item.id}>
              <ProvinceSelect
                provincesList={provincesList}
                districtsList={districtsList}
                subdistrictsList={subdistrictsList}
                fetchProvince={fetchProvince}
                fetchDistrict={fetchDistrict}
                selectProvinces={selectProvinces}
                selectDistricts={selectDistricts}
                selectSubdistricts={selectSubdistricts}
                index={index}
                data={item}
              />
              <Icon
                className='dynamic-delete-button'
                type='minus-circle-o'
                onClick={() => this.remove(index)}
              />
            </div>
          ))
        }
        <Button type='dashed' onClick={this.addMoreProvince} style={{ width: '60%' }}>
          <Icon type='plus' /> เพิ่มจังหวัด
        </Button>
      </>
    )
  }
}

export default ProvinceSelectList
