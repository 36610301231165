import React from 'react'
import { Layout, Menu, Icon } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

const { SubMenu } = Menu

const { Header, Content, Sider } = Layout

class PageLayout extends React.Component {
  logout = async () => {
    await this.props.logout()
    this.props.history.push('/login')
  }

  render() {
    const { children } = this.props
    const k = this.props.location.pathname.toString()
    const mainmenu = k.split('/')
    return (
      <Layout>
        <Header className="header">
          <div className="logo" />
          <a href="/">
            <img src="/baania_logo.png" height="60" />
          </a>
        </Header>
        <Layout>
          <Sider width={200} style={{ background: '#fff' }}>
            <Menu
              mode="inline"
              defaultSelectedKeys={[mainmenu[1]]}
              selectedKeys={[this.props.location.pathname]}
              defaultOpenKeys={[mainmenu[1]]}
              style={{ height: '100%', borderRight: 0 }}
            >
              <Menu.Item key="dashboard">
                <Icon type="dashboard" />
                <span>
                  <Link to="/dashboard">แดชบอร์ด</Link>
                </span>
              </Menu.Item>
              <SubMenu
                key="articles"
                title={
                  <span>
                    <Icon type="bars" />
                    บทความ
                  </span>
                }
              >
                <Menu.Item key="/articles">
                  <Link to="/articles">รายการบทความ</Link>
                </Menu.Item>
                <Menu.Item key="/articles/create">
                  <Link to="/articles/create">สร้างบทความ </Link>
                </Menu.Item>
              </SubMenu>

              <Menu.Item key="/categories">
                <Link to="/categories">
                  <Icon type="bars" />
                  รายการหมวด
                </Link>
              </Menu.Item>

              <Menu.Item key="logout">
                <Icon type="logout" />
                <span onClick={() => this.logout()}>Logout</span>
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout style={{ padding: '0 24px 24px' }}>
            <Content
              style={{
                background: '#fff',
                padding: 24,
                margin: '34px 10px 10px 10px',
                minHeight: '80vh',
              }}
            >
              {children}
            </Content>
          </Layout>
        </Layout>
      </Layout>
    )
  }
}

const mapDispatchToProp = ({ auth }) => ({
  logout: auth.logoutAsync,
})
const WrapLayout = withRouter(PageLayout)

export default connect(null, mapDispatchToProp)(WrapLayout)
