import React, { Component } from 'react'
import { Form, Icon, Input, Button, Checkbox, message } from 'antd'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

// import Container from '../../components/Container'
const Container = styled.div`
  height: 100vh;
  display: flex;
`

class Login extends Component {
  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        try {
          await this.props.login(values)
          this.props.history.push('/dashboard')
        } catch (error) {
          message.error('Login Error')
          console.log('Login Error', error)
        }
      }
    })
  }

  render () {
    if (this.props.isAuth === null) {
      return null
    }
    if (this.props.isAuth) {
      return <Redirect to='/dashboard' />
    }
    const { getFieldDecorator } = this.props.form
    return (
      <Container>
        <Form onSubmit={this.handleSubmit} className='login-form'>
          <div style={{ textAlign: 'center' }}>
            <h1>Baania</h1>
          </div>
          <Form.Item>
            {getFieldDecorator('email', {
              rules: [{ required: true, message: 'Please input your email!' }],
            })(
              <Input
                prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder='Email'
              />,
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: 'Please input your Password!' }],
            })(
              <Input
                prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
                type='password'
                placeholder='Password'
              />,
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('remember', {
              valuePropName: 'checked',
              initialValue: true,
            })(<Checkbox>Remember me</Checkbox>)}
            <a className='login-form-forgot' href=''>
          Forgot password
            </a>
            <Button type='primary' htmlType='submit' className='login-form-button'>
          Log in
            </Button>
        Or <a href=''>register now!</a>
          </Form.Item>
        </Form>
      </Container>

    )
  }
}

const WrappedLoginForm = Form.create()(Login)

const mapDispatchToProp = ({ auth }) => ({
  login: auth.loginAsync,
})

const mapStateToProps = ({ auth }) => ({
  isAuth: auth.isAuth
})

export default connect(mapStateToProps, mapDispatchToProp)(WrappedLoginForm)
