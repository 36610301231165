import Resource from '../utils/resource'
import config from '../utils/config'

export const CacheAPI = new Resource('/cache', {
  delKey: {
    url: 'delkey',
    method: 'post',
    baseURL: `${config.BAANIA_API}v1/`
  }
})
