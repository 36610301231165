import { init } from '@rematch/core'
import createLoadingPlugin from '@rematch/loading'
import createRematchPersist from '@rematch/persist'

import * as models from './models'

const persistPlugin = createRematchPersist({
  whitelist: ['auth'],
  throttle: 5000,
  version: 1
})

const loading = createLoadingPlugin()
const store = init({
  models,
  plugins: [loading, persistPlugin]
})

export default store
