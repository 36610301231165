import React, { useEffect, useState } from 'react'
import { useHistory, Link, useLocation } from 'react-router-dom'
import _ from 'lodash'
import { Table, Button } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import Layout from '../../components/Layout'
import { CategoryAPI } from '../../services'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

function Categories() {
  const location = useLocation()
  console.log(location)
  const columns = [
    {
      title: 'ลำดับ',
      dataIndex: 'order',
      key: 'order',
    },
    {
      title: 'รูป',
      dataIndex: 'image_url',
      key: 'image_url',
      render: (url) => {
        return <img src={url} alt="" width={50} />
      },
    },
    {
      title: 'ชื่อ',
      dataIndex: 'title_th',
      key: 'title_th',
    },
    {
      title: 'หมวดหลัก',
      dataIndex: 'parent.title_th',
      key: 'parent_id',
    },
    {
      title: 'ชั้นที่',
      dataIndex: 'level',
      key: 'level',
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'status',
      render: (s) => {
        return s == 1 ? 'ใช้งาน' : 'ปิด'
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (record) => {
        let SubCate = null
        if (record.subcategories && record.subcategories.length > 0) {
          const level = 1 + +record.level
          const parent_id = record.id
          SubCate = (
            <Link to={`/categories?level=${level}&parent_id=${parent_id}`}>
              <div>ดูหมวดย่อย</div>
            </Link>
          )
        }
        const Edit = (
          <div style={{ margin: '0 10px' }}>
            <Link to={`/categories/${record.id}?level=${record.level}&parent_id=${record.parent_id}`}>
              <div>แก้ไข</div>
            </Link>
          </div>
        )
        return (
          <div style={{ display: 'flex' }}>
            {Edit}
            {SubCate}
          </div>
        )
      },
    },
  ]

  const query = useQuery()
  const level = query.get('level') || 1
  const parentId = query.get('parent_id') || '0'
  const [categories, setCategories] = useState([])

  const fetchData = async () => {
    const result = await CategoryAPI.find({ filter: { where: { parent_id: parentId }, include: ['parent', 'subcategories'] } })
    setCategories(_.orderBy(result, 'order'))
  }

  useEffect(() => {
    fetchData()

    return () => {}
  }, [location.search])
  return (
    <Layout>
      {_.get(categories[0], 'parent.id') && _.get(categories[0], 'parent.id') !== '0' && (
        <Link to={`/categories?level=${level - 1}&parent_id=${categories[0].parent.parent_id}`}>
          <small>
            <LeftOutlined /> ย้อนไปหมวดชั้นก่อน
          </small>
        </Link>
      )}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h2 id="rowSelection">
          <span>หมวด</span>
        </h2>
        <div>
          <Link to={`/categories/create?level=${level}&parent_id=${parentId}`}>
            <Button>สร้างหมวดย่อยในหมวดนี้</Button>
          </Link>
        </div>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={categories}
        expandable={false}
        // loading={this.state.loading}
        // onChange={this.props.handleTableChange}
      />
    </Layout>
  )
}

export default Categories
