import Resource from '../utils/resource'

export const CategoryAPI = new Resource('/article_categories', {
  tree: {
    url: '/article_categories/tree',
    method: 'get',
  },
  add: {
    url: 'add',
    method: 'post',
  },
  updateById: {
    url: 'updateById',
    method: 'patch',
  },
  getById: {
    url: 'getById',
    method: 'get',
  },
  updateOrder: {
    url: 'updateOrder',
    method: 'patch',
  },
})
