import React from 'react'
import _ from 'lodash'
import Layout from '../../components/Layout'
import ArticleTable from '../../components/ArticleTable'
import { ArticleAPI, CategoryAPI } from '../../services'
import { Input, Button, Form, Select } from 'antd'
const { Option } = Select

class ArticleList extends React.Component {
  state = {
    data: [],
    pagination: {},
    cat1: [],
    cat2: [],
    cat3: [],
    search: null,
    filters: null,
  }

  componentDidMount() {
    this.fetch()
    this.fetchCategory()
  }
  fetchCategory = async () => {
    const catList = await CategoryAPI.find({ filter: { where: { level: { neq: 0 } } } })
    const groupCat = _.groupBy(catList, 'level')
    this.setState({
      cat1: groupCat[1],
      cat2: groupCat[2].map((i) => ({ ...i, title_th: `${groupCat[1].find((j) => i.parent_id === j.id).title_th}/${i.title_th}` })),
      cat3: groupCat[3].map((i) => {
        const parent = groupCat[2].find((j) => i.parent_id === j.id)
        const grandParent = groupCat[1].find((k) => parent.parent_id === k.id)
        return {
          ...i,
          title_th: `${grandParent.title_th}/${parent.title_th}/${i.title_th}`,
        }
      }),
    })
  }

  fetch = async (params = {}) => {
    // const categories = await CategoryAPI.tree()
    let filters = {
      order: 'created DESC',
      where: {},
      limit: 10,
    }

    if (params.page) {
      filters.skip = filters.limit * (params.page - 1)
      filters.limit = params.pageSize
    }

    if (params.search) {
      filters.where = {
        ...filters.where,
        'data.title': { like: params.search || '' },
      }
    }

    if (params.status && params.status !== 'all') {
      filters.where = {
        ...filters.where,
        'data.status': params.status === 'publish' ? 1 : 0,
      }
    }

    if (params.category && params.category !== 'all') {
      if (params.category === 'empty') {
        filters.where = {
          ...filters.where,
          'data.category': [],
        }
      } else {
        filters.where = {
          ...filters.where,
          'data.category._id': params.category,
        }
      }
    }

    if (params.subcategory && params.subcategory !== 'all') {
      filters.where = {
        ...filters.where,
        'data.category._id': params.subcategory,
      }
    }

    if (params.subsubcategory && params.subsubcategory !== 'all') {
      filters.where = {
        ...filters.where,
        'data.category._id': params.subsubcategory,
      }
    }

    filters.order = 'data.date_article DESC'

    this.setState({
      filters: params,
    })
    const articles = await ArticleAPI.find({
      filter: filters,
    })

    const countArticles = await ArticleAPI.count({
      where: filters.where,
    })

    const pagination = { ...this.state.pagination }
    pagination.total = countArticles.count
    this.setState({
      data: articles,
      pagination,
      // categoriesList: categories,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.fetch(values)
        this.setState({
          search: values.search,
        })
      }
    })
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.fetch({
      ...this.state.filters,
      page: pagination.current,
      pageSize: pagination.pageSize,
      search: this.state.search,
    })
  }

  disableSelect = (field) => {
    const { getFieldValue } = this.props.form
    const allField = ['category', 'subcategory', 'subsubcategory']
    const otherField = allField.filter((i) => i !== field)
    const checkInclude = (f) => {
      return ![null, undefined, 'all'].includes(getFieldValue(f))
    }
    return otherField.some(checkInclude)
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { cities } = this.state
    return (
      <Layout>
        <h2 id="rowSelection">
          <span>รายการบทความทั้งหมด</span>
        </h2>
        <Form layout="inline" onSubmit={this.handleSubmit}>
          <Form.Item>{getFieldDecorator('category', {})(<label>ค้นหาบทความ :</label>)}</Form.Item>
          <Form.Item>
            {getFieldDecorator(
              'category',
              {}
            )(
              <Select placeholder="หมวดหลัก" style={{ width: 160 }} disabled={this.disableSelect('category')}>
                <Option value="all">ทั้งหมด</Option>
                <Option value="empty">ไม่มีหมวด</Option>
                {this.state.cat1.map((category) => (
                  <Option key={category.id}>{category.title_th}</Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator(
              'subcategory',
              {}
            )(
              <Select placeholder="หมวดย่อย" style={{ width: 300 }} disabled={this.disableSelect('subcategory')}>
                <Option value="all">ทั้งหมด</Option>
                {this.state.cat2.map((subcat) => (
                  <Option key={subcat.id}>{subcat.title_th}</Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator(
              'subsubcategory',
              {}
            )(
              <Select placeholder="หมวดย่อยย่อย" style={{ width: 400 }} disabled={this.disableSelect('subsubcategory')}>
                <Option value="all">ทั้งหมด</Option>
                {this.state.cat3.map((subcat) => (
                  <Option key={subcat.id}>{subcat.title_th}</Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator(
              'status',
              {}
            )(
              <Select style={{ width: 120 }} placeholder="สถานะ">
                <Option value="all">ทั้งหมด</Option>
                <Option value="publish">เผยแพร่</Option>
                <Option value="unpublish">ไม่เผยแพร่</Option>
              </Select>
            )}
          </Form.Item>

          <Form.Item>{getFieldDecorator('search', {})(<Input placeholder="ค้นหา" />)}</Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              ค้นหา
            </Button>
          </Form.Item>
        </Form>{' '}
        <br />
        <ArticleTable data={this.state.data} pagination={this.state.pagination} handleTableChange={this.handleTableChange} fetchData={this.fetch} />
      </Layout>
    )
  }
}

const Article = Form.create()(ArticleList)
export default Article
