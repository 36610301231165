/**
*
* PrivateRoute
*
*/

import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  ACCESSTOKEN_KEY
} from '../../utils/constant'

function PrivateRoute ({ component: Component, isAuth, ...rest }) {
  if (isAuth === null) {
    return null
  }
  if (!isAuth) {
    localStorage.setItem(ACCESSTOKEN_KEY, undefined)
    return (
      <Redirect
        to={{
          pathname: '/login'
        }}
      />
    )
  }

  return (
    <Route
      {...rest}
      render={props => (
        <Component {...props} />
      )
      }
    />
  )
}

// PrivateRoute.propTypes = {
//   component: PropTypes.any, // eslint-disable-line
//   location: PropTypes.shape({

//   }).isRequired,
// }

const mapState = ({ auth }) => ({
  isAuth: auth.isAuth
})

export default connect(mapState)(PrivateRoute)
