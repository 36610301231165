import React, { useEffect, useState } from 'react'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import _ from 'lodash'
import Layout from '../../components/Layout'
import CategoriesForm from '../../components/CategoriesForm'
import { CategoryAPI } from '../../services'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

function EditCategories() {
  const query = useQuery()
  const history = useHistory()
  const [initialValue, setinitialValue] = useState(null)
  const { id } = useParams()

  const fetchData = async () => {
    const result = await CategoryAPI.getById({ id })
    setinitialValue(result)
  }

  const onSubmit = async (data, order) => {
    if (order.initOrder !== order.updateOrder) {
      await CategoryAPI.updateOrder({
        parent_id: data.parent_id,
        initOrder: order.initOrder,
        updateOrder: order.updateOrder
      })
    }
    const result = await CategoryAPI.updateById({ id, ...data })
    setinitialValue(result)

    history.push(`/categories?level=${query.get('level') || 1}&parent_id=${query.get('parent_id') || '0'}`)
  }
  useEffect(() => {
    fetchData()
  }, [id])

  return (
    <Layout>
      <h2 id='rowSelection'>
        <span>หมวด</span>
      </h2>
      {initialValue === null ? null : <CategoriesForm initialValue={initialValue} onSubmit={onSubmit} />}
    </Layout>
  )
}

export default EditCategories
