import React from 'react'

import Layout from '../../components/Layout'

class Dashboard extends React.Component {
  render () {
    return <Layout>แดชบอร์ด</Layout>
  }
}

export default Dashboard
