import React from 'react'
import { Table, Divider, Popconfirm, Icon, message } from 'antd'
import { Link } from 'react-router-dom'
import { AdsAPI } from '../../services'
import moment from 'moment'

class AdsTable extends React.Component {
    state = {
      loading: false
    };

    confirm = async (id) => {
      await AdsAPI.delete({ id })
      message.success('ลบแบนเนอร์โฆษณาเรียบร้อยแล้ว')
      this.props.fetchData()
    }

    render () {
      const columns = [
        {
          title: 'รูป',
          dataIndex: 'data.banner',
          key: 'data.banner',
          render: (data) => {
            if (data) {
              return <img width={100} alt={data[0].name} src={data[0].url} />
            }
          }
        },
        {
          title: 'ชื่อแบนเนอร์',
          dataIndex: 'data.title',
          key: 'data.title'
        },
        {
          title: 'ตำแหน่งการแสดง',
          dataIndex: 'data.position',
          key: 'data.position'
        },
        {
          title: 'วันที่เริ่มแสดง',
          dataIndex: 'data.date_start',
          key: 'data.date_start',
          render: (data) => moment(data).format('DD/MM/YYYY')
        },
        {
          title: 'สิ้นสุดวันที่',
          dataIndex: 'data.date_end',
          key: 'data.date_end',
          render: (data) => moment(data).format('DD/MM/YYYY')
        },
        {
          title: 'จัดการ',
          key: 'action',
          render: (text, record) => (
            <span>
              <Link
                to={`/ads/${record.id}/edit`}
              >Edit
              </Link>
              <Divider type='vertical' />
              <Popconfirm
                title='ต้องการลบแบนเนอร์โฆษณานี้？'
                icon={<Icon type='question-circle-o' style={{ color: 'red' }} />}
                onConfirm={() => {
                  this.confirm(record.id)
                }}
                okText='ใช่'
                cancelText='ไม่ใช่'
              >
                <a href='#'>Delete</a>
              </Popconfirm>
            </span>
          )
        }
      ]

      return (
        <Table
          columns={columns}
          rowKey={record => record.id}
          dataSource={this.props.data}
          pagination={this.props.pagination, { defaultPageSize: 10, showSizeChanger: true, total: this.props.pagination.total, showTotal: total => `Total ${total} items` }}
          loading={this.state.loading}
          onChange={this.props.handleTableChange}
        />
      )
    }
}
export default AdsTable
