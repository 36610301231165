import React from 'react'
import { Upload, Icon, Button } from 'antd'
import _isEqual from 'lodash/isEqual'

import { FilesAPI } from '../../services'

class UploadComponent extends React.Component {
  state = {
    fileList: [],
    loading: false
  }

  componentDidUpdate (prevProps) {
    if (!_isEqual(prevProps.defaultFileList, this.props.defaultFileList)) {
      this.setState({
        fileList: this.props.defaultFileList.map(item => ({ uid: Date.now(), status: 'done', name: item.description, ...item }))
      }, () => {
        this.props.onUpload([...this.state.fileList])
      })
    }
  }

  uploadFile = async (file) => {
    this.setState({
      loading: true
    }, async () => {
      try {
        const formData = new FormData()
        formData.append('file', file.file)
        formData.append('folderName', this.props.folderName)
        const response = await FilesAPI.upload(formData)

        if (response) {
          const fileList = [...this.state.fileList]
          const newFile = {
            uid: file.file.uid,
            name: file.file.name,
            status: 'done',
            url: response.url.main || response.url,
            thumbnail: response.url.thumbnail || ''
          }
          var reader = new FileReader()
          reader.readAsDataURL(file.file)
          reader.onload = function (e) {
            var image = new Image()
            image.src = e.target.result
            image.onload = function () {
              var height = this.height
              var width = this.width
              newFile.height = height
              newFile.width = width
            }
          }
          this.props.onUpload([...fileList, newFile])
          this.setState({ loading: false, fileList: [...fileList, newFile] })
        }
      } catch (error) {
        this.setState({
          loading: false
        })
      }
    })
  }

  handleChangeUpload = ({ fileList, file }) => {
    if (file.status === 'removed') {
      this.props.onUpload(fileList)
    }
    this.setState({ fileList: fileList.filter(item => item.status === 'done') })
  }

  render () {
    const { listType = 'picture-card', accept = '', multiple = true } = this.props
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className='ant-upload-text'>Upload</div>
      </div>
    )
    const button = (
      <Button>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
          Upload
      </Button>
    )
    return (
      <Upload
        listType={listType}
        multiple={multiple}
        accept={accept}
        disabled={this.state.loading}
        customRequest={this.uploadFile}
        fileList={this.state.fileList}
        onChange={this.handleChangeUpload}
      >
        {this.props.button ? button : uploadButton}
      </Upload>
    )
  }
}

export default UploadComponent
