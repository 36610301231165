import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import Layout from '../../components/Layout'
import CategoriesForm from '../../components/CategoriesForm'
import { CategoryAPI } from '../../services'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

function CreateCategories() {
  const history = useHistory()
  const query = useQuery()
  const level = +query.get('level')
  const parent_id = query.get('parent_id')
  const [order, setorder] = useState()
  const fetchCount = async () => {
    const getCount = await CategoryAPI.count({ where: { parent_id } })
    const { count = 10 } = getCount
    setorder(count + 1)
  }
  useEffect(() => {
    fetchCount()
  }, [])
  if (level < 0 || level > 3 || !parent_id) {
    return history.push('/categories')
  }

  const onSubmit = async (data, order) => {
    const result = await CategoryAPI.add({ ...data })
    history.push(`/categories?level=${level}&parent_id=${parent_id}`)
  }
  return (
    <Layout>
      <h2 id="rowSelection">
        <span>หมวด</span>
      </h2>
      <CategoriesForm initialValue={{ level, parent_id, order }} onSubmit={onSubmit} create />
    </Layout>
  )
}

export default CreateCategories
