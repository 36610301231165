import React from 'react'
import { Select } from 'antd'
import _get from 'lodash/get'

const { Option } = Select

const neighborhoodsProvince = [
  {
    province_id: '3781',
    name_th: 'กรุงเทพมหานคร',
    name_en: 'BANGKOK'
  },
  {
    province_id: '4510',
    name_th: 'ฉะเชิงเทรา',
    name_en: 'CHACHOENGSAO'
  },
  {
    province_id: '1005',
    name_th: 'เชียงใหม่',
    name_en: 'CHIANG MAI'
  },
  {
    province_id: '2411',
    name_th: 'เชียงราย',
    name_en: 'CHIANG RAI'
  },
  {
    province_id: '4616',
    name_th: 'ชลบุรี',
    name_en: 'CHON BURI'
  },
  {
    province_id: '2637',
    name_th: 'ขอนแก่น',
    name_en: 'KHON KAEN'
  },
  {
    province_id: '2857',
    name_th: 'นครราชสีมา',
    name_en: 'NAKHON RATCHASIMA'
  },
  {
    province_id: '3372',
    name_th: 'นนทบุรี',
    name_en: 'NONTHABURI'
  },
  {
    province_id: '3599',
    name_th: 'ปทุมธานี',
    name_en: 'PATHUM THANI'
  },
  {
    province_id: '3498',
    name_th: 'สมุทรปราการ',
    name_en: 'SAMUT PRAKAN'
  }
]
class NeighborSelect extends React.Component {
  async componentDidMount () {
    if (_get(this, 'props.data.province_id')) {
      this.props.fetchNeighbor(this.props.data.province_id)
    }
  }

  selectNeighborhood = async (value) => {
    this.props.fetchNeighbor(value)
    this.props.selectNeighborProvince(this.props.index, value)
  }

  render () {
    const { data, neighborhoodsList } = this.props
    return (
      <>
        <Select
          showSearch
          value={data.province_id}
          style={{ width: 250 }}
          placeholder='เลือกจังหวัด'
          onChange={this.selectNeighborhood}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {
            neighborhoodsProvince.map((item) => <Option key={item.name_en} value={item.province_id}>{item.name_th}</Option>)
          }
        </Select>
        <Select
          showSearch
          value={data.neighbor_id}
          style={{ width: 250 }}
          placeholder='เลือกย่าน'
          onChange={(e) => this.props.selectNeighbor(this.props.index, e)}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {
            neighborhoodsList.map((item) => <Option key={item.code} value={item.code}>{item.name_th}</Option>)
          }
        </Select>
      </>
    )
  }
}

export default NeighborSelect
