const ENV = {
  local: {
    API_URL: 'http://localhost:3000/api/',
    SITE_URL: 'http://localhost:8080/th/',
    BAANIA_API: 'http://localhost:3333/api/'
  },
  dev: {
    API_URL: 'https://cms.baania.dev/api/',
    SITE_URL: 'https://baania.dev/th/',
    BAANIA_API: 'https://api.baania.dev/api/'
  },
  prod: {
    API_URL: 'https://cms.baania.com/api/',
    SITE_URL: 'https://www.baania.com/th/',
    BAANIA_API: 'https://api.baania.com/api/'
  }
}

function getEnvVars(env = '') {
  if (env === null || env === undefined || env === '') return ENV.local

  if (env.indexOf('dev') !== -1) return ENV.dev
  if (env.indexOf('staging') !== -1) return ENV.staging
  if (env.indexOf('prod') !== -1) return ENV.prod
  return ENV.dev
}

export default getEnvVars(process.env.REACT_APP_STAGE)
