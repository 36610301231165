import React from 'react'
import { Form, message } from 'antd'
import { withRouter } from 'react-router-dom'

import Layout from '../../components/Layout'
import ArticleForm from '../../components/ArticleForm'
import { ArticleAPI } from '../../services'

class ArticleEdit extends React.Component {
  state = {
    data: {},
    keyId: null
  }

  async componentDidMount() {
    const { id } = this.props.match.params
    if (!id) return this.props.history.push('/articles')
    try {
      const result = await ArticleAPI.findById({ id })

      return this.setState({
        data: result.data,
        keyId: result.keyId
      })
    } catch (err) {
      return this.props.history.push('/articles')
    }
  }

  onSubmit = async (values) => {
    try {
      await ArticleAPI.update(values)
      message.success('แก้ไขบทความสำเร็จ')
      this.props.history.push('/articles')
    } catch (e) {
      message.error('แก้ไขบทความผิดพลาด')
      console.error('Error ==>>', e)
    }
  }

  render() {
    return (
      <Layout>
        <h2 id='rowSelection'>
          <span>แก้ไขบทความ</span>
        </h2>

        <ArticleForm title='แก้ไขบทความ' data={this.state.data} keyId={this.state.keyId} onSubmit={this.onSubmit} />
      </Layout>
    )
  }
}

const WrapArticleEdit = Form.create()(ArticleEdit)

export default withRouter(WrapArticleEdit)
