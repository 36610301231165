// import { createSelector } from 'reselect';
import {
  ACCESSTOKEN_KEY
} from '../utils/constant'
import {
  UserAPI,
} from '../services'

export const auth = {
  state: {
    accessToken: false,
    userInfo: {},
    isAuth: null,
    error: false
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    setToken (state, payload) {
      return {
        ...state,
        accessToken: payload
      }
    },
    setUserInfo (state, payload) {
      return {
        ...state,
        userInfo: payload
      }
    },
    setIsAuth (state, payload) {
      return {
        ...state,
        isAuth: payload
      }
    },
    logout (state) {
      return {
        ...state,
        userInfo: {},
        accessToken: null,
        isAuth: false
      }
    }
  },
  effects: () => ({
    async loginAsync (payload) {
      try {
        const result = await UserAPI.login(payload)
        const token = result.id
        localStorage.setItem(ACCESSTOKEN_KEY, token)
        const userProfile = result.user
        this.setToken(token)
        this.setIsAuth(true)
        this.setUserInfo(userProfile)
        return Promise.resolve(result)
      } catch (e) {
        this.setIsAuth(false)
        console.log('error ==>>', e)
        return Promise.reject(e)
      }
    },
    async getUserInfoAsync () {
      try {
        const result = await UserAPI.getUserProfile()
        this.setIsAuth(true)
        this.setUserInfo(result)
        return Promise.resolve(result)
      } catch (e) {
        this.setIsAuth(false)
        console.log('error ==>>', e)
        return Promise.reject(e)
      }
    },
    async logoutAsync () {
      try {
        localStorage.clear()
        this.logout()
      } catch (error) {
        throw error
      }
    }
  })
}
