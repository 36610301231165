import React from 'react'
import { Form, message } from 'antd'
import { withRouter } from 'react-router-dom'

import Layout from '../../components/Layout'
import ArticleForm from '../../components/ArticleForm'
import { ArticleAPI } from '../../services'

class CreateForm extends React.Component {
  onSubmit = async (values) => {
    try {
      await ArticleAPI.create(values)
      message.success('สร้างบทความสำเร็จ')
      this.props.history.push('/articles')
    } catch (e) {
      message.error('สร้างบทความผิดพลาด')
      console.error('Error ==>>', e)
    }
  }

  render() {
    return (
      <Layout>
        <h2 id="rowSelection">
          <span>สร้างบทความ</span>
        </h2>

        <ArticleForm title="เพิ่มบทความ" onSubmit={this.onSubmit} />
      </Layout>
    )
  }
}

const ArticleCreateForm = Form.create()(CreateForm)

export default withRouter(ArticleCreateForm)
