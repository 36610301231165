import React from 'react'
import { Table, Divider, Popconfirm, Icon, message, Popover, Button } from 'antd'
import moment from 'moment'
import { Link } from 'react-router-dom'
import _isEmpty from 'lodash/isEmpty'
import _has from 'lodash/has'
import _ from 'lodash'
import { ArticleAPI, CacheAPI } from '../../services'
import _config from './../../utils/config'

class ArticleTable extends React.Component {
  state = {
    loading: false
  }

  confirm = async (id) => {
    //await ArticleAPI.delete({ id })
    await ArticleAPI.update({ id, deletedAt: moment().format() })
    message.success('ลบบทความเรียบร้อยแล้ว')
    this.props.fetchData()
  }

  render() {
    const columns = [
      {
        title: 'เผยแพร่',
        dataIndex: 'data.status',
        key: 'data.status',
        render: (data) =>
          data ? (
            <Icon
              type='eye'
              theme='filled'
              style={{ fontSize: '20px' }}
              theme='twoTone'
              twoToneColor='#2ECC71'
            />
          ) : (
            <Icon
              type='eye-invisible'
              theme='twoTone'
              twoToneColor='#CECECE'
              style={{ fontSize: '20px' }}
            />
          )
      },
      {
        title: 'รูป',
        dataIndex: 'data.images',
        key: 'data.images',
        render: (data) => {
          if (!_isEmpty(data)) {
            return (
              <Popover
                placement='rightTop'
                title={'ดูตัวอย่างรูปภาพ'}
                content={<img width={450} alt={data[0].name} src={data[0].url} />}
                trigger='click'
              >
                <img width={40} alt={data[0].name} src={data[0].url} />
              </Popover>
            )
          }
        }
      },
      {
        title: 'วันที่บทความ',
        dataIndex: 'data.date_article',
        key: 'data.date_article',
        // render: (data) => moment.unix(data).format('DD/MM/YYYY')
        render: (data) => {
          return moment.unix(data).format('DD/MM/YYYY')
        }
      },
      {
        title: 'หมวดหมู่',
        dataIndex: 'data.category',
        key: 'data.category',
        render: (data) => {
          // if (_has(data, 'title') && _has(data.sub_categories[0], 'title')) {
          //   return data.title.title_th + ' / ' + data.sub_categories[0].title.title_th
          // } else if (_has(data, 'title') && !_has(data.sub_categories[0], 'title')) {
          //   return data.title.title_th
          // } else {
          //   return '-'
          // }
          if (Array.isArray(data)) {
            return _.sortBy(data, 'level')
              .map((i) => i.title_th)
              .join('/')
          }
          return '-'
        }
      },
      {
        title: 'ชื่อบทความ',
        dataIndex: 'data.title',
        key: 'data.title',
        render: (data, record) => {
          if (!record.data.status) {
            return (
              <a target='_blank' href={`${_config.SITE_URL}article/draft/${record.keyId}`}>
                {data}
              </a>
            )
          } else {
            const alias = record.data.url.alias_th
            const checkLast = alias.substr(-1)

            if (checkLast === '-') {
              return (
                <a
                  target='_blank'
                  href={`${_config.SITE_URL}article/${record.data.url.alias_th}${record.keyId}`}
                >
                  {data}
                </a>
              )
            } else {
              return (
                <a
                  target='_blank'
                  href={`${_config.SITE_URL}article/${record.data.url.alias_th}-${record.keyId}`}
                >
                  {data}
                </a>
              )
            }
          }
        }
      },
      {
        title: 'Cache',
        key: 'cache',
        render: (data, record) => (
          <Button
            onClick={async () => {
              try {
                await CacheAPI.delKey({
                  cacheKey: `/th/api/v1/articles/${data.keyId}`,
                  url: `https://www.baania.com/th/article/${data.data.url.alias_th}-${data.keyId}`
                })
                await CacheAPI.delKey({
                  cacheKey: `/en/api/v1/articles/${data.keyId}`,
                  url: `https://www.baania.com/th/article/${data.data.url.alias_th}-${data.keyId}`
                })
                message.success('clear cache success')
              } catch (err) {
                console.log(err)
              }
            }}
          >
            Clear Cache
          </Button>
        )
      },
      {
        title: 'จัดการ',
        key: 'action',
        render: (data, record) => (
          <span>
            <Link to={`article/${record.id}/edit`}>Edit</Link>
            <Divider type='vertical' />
            <Popconfirm
              title='ต้องการลบบทความนี้？'
              icon={<Icon type='question-circle-o' style={{ color: 'red' }} />}
              onConfirm={() => {
                this.confirm(record.id)
              }}
              okText='ใช่'
              cancelText='ไม่ใช่'
            >
              <a href='#'>Delete</a>
            </Popconfirm>
          </span>
        )
      }
    ]
    return (
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={this.props.data}
        pagination={
          (this.props.pagination,
          {
            defaultPageSize: 10,
            showSizeChanger: true,
            total: this.props.pagination.total,
            showTotal: (total) => `Total ${total} items`,
            search: this.state.search
          })
        }
        loading={this.state.loading}
        onChange={this.props.handleTableChange}
      />
    )
  }
}
export default ArticleTable
