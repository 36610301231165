import React from 'react'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import _isEmpty from 'lodash/isEmpty'

import PrivateRoute from './components/PrivateRoute'
import Login from './pages/Login'
import Ads from './pages/Ads'
import Dashboard from './pages/Dashboard'
import Article from './pages/Article'
import ArticleCreate from './pages/ArticleCreate'
import ArticleEdit from './pages/ArticleEdit'
import AdsCreate from './pages/AdsCreate'
import AdsEdit from './pages/AdsEdit'
import Categories from './pages/Categories'
import CreateCategories from './pages/CreateCategories'
import EditCategories from './pages/EditCategories'
import './App.css'

class App extends React.Component {
  componentDidMount() {
    const { auth } = this.props
    if (_isEmpty(auth.userInfo) && !auth.isAuth) {
      this.getUser()
    }
  }

  getUser = async () => {
    try {
      await this.props.getUserInfoAsync()
    } catch (error) {
      console.error('Error: ', error)
    }
  }

  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <Switch>
            <PrivateRoute path="/dashboard" component={Dashboard} />

            <PrivateRoute path="/article/:id/delete" component={ArticleEdit} />
            <PrivateRoute path="/article/:id/edit" component={ArticleEdit} />
            <PrivateRoute path="/articles/create" component={ArticleCreate} />
            <PrivateRoute path="/articles" component={Article} />
            <PrivateRoute path="/categories/create" component={CreateCategories} exact />
            <PrivateRoute path="/categories/:id" component={EditCategories} exact />
            <PrivateRoute path="/categories" component={Categories} exact />

            <PrivateRoute exact path="/ads/:id/delete" component={AdsEdit} />
            <PrivateRoute exact path="/ads/:id/edit" component={AdsEdit} />
            <PrivateRoute exact path="/ads/create" component={AdsCreate} />
            <PrivateRoute exact path="/ads" component={Ads} />

            <Route path="/login" component={Login} />
            <Route path="/" component={Login} />
          </Switch>
        </BrowserRouter>
      </div>
    )
  }
}

const mapStateToProps = ({ auth }) => ({
  auth,
})

const mapDispatchToProps = ({ auth }) => ({
  getUserInfoAsync: auth.getUserInfoAsync,
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
