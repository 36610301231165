import React from 'react'
import { Form, Input, Button, InputNumber, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { FilesAPI } from '../../services'
import Editor from '../Editor'

const { Dragger } = Upload

const genSlug = (text = '') => {
  const specialChar = [';', ':', '!', '*', '"', "'", '-', '/', '?', '“', '&', '[', ']', '(', ')', '{', '}', '%', '"']
  try {
    return text
      .trim()
      .split('')
      .filter((i) => !specialChar.includes(i))
      .join('')
      .replace(/\s/gi, '-')
      .toLocaleLowerCase()
  } catch (error) {
    return null
  }
}

class CategoriesForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { onSubmit, initialValue } = this.props

        values.title_en = values.title_th
        values.slug_en = values.slug_th
        values.description_en = values.description_th
        values.status = 1

        if (onSubmit) {
          onSubmit(values, { initOrder: initialValue.order, updateOrder: values.order })
        }
      }
    })
  }

  uploadFile = async (file) => {
    try {
      const { setFieldsValue } = this.props.form
      const formData = new FormData()
      formData.append('file', file.file)
      formData.append('folderName', this.props.folderName)
      const response = await FilesAPI.upload(formData)
      setFieldsValue({ image_url: response.url.thumbnail })
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    const { form, initialValue = {} } = this.props
    const { getFieldDecorator, setFieldsValue, getFieldValue } = form
    return (
      <Form labelCol={{ span: 5 }} wrapperCol={{ span: 12 }} onSubmit={this.handleSubmit}>
        {getFieldDecorator('parent_id', { initialValue: initialValue.parent_id })(<Input hidden />)}
        {getFieldDecorator('level', { initialValue: initialValue.level })(<Input hidden />)}
        <Form.Item label='ลำดับ'>
          {getFieldDecorator('order', { initialValue: initialValue.order })(
            <InputNumber min={1} max={999} disabled={this.props.create} />
          )}
        </Form.Item>
        <Form.Item label='รูปภาพ'>
          {getFieldDecorator('image_url', {
            rules: [{ required: true, message: 'บังคับใส่ต้องอัพโหลดรูปหมวด!' }],
            initialValue: initialValue.image_url
          })(<Input hidden />)}
          {getFieldValue('image_url') ? (
            <div style={{ textAlign: 'center' }}>
              <img src={getFieldValue('image_url') || ''} alt='categories_image' width={100} />
              <div>
                <Upload accept='image/*' customRequest={this.uploadFile} showUploadList={false}>
                  <Button>
                    <UploadOutlined /> เปลี่ยนไฟล์
                  </Button>
                </Upload>
              </div>
            </div>
          ) : (
            <Dragger accept='image/*' customRequest={this.uploadFile} showUploadList={false} style={{ width: '100%' }}>
              <p className='ant-upload-drag-icon'>
                <UploadOutlined />
              </p>
              <p className='ant-upload-text'>คลิกหรือลากไฟล์มาวางที่นี่เพื่ออัพโหลด</p>
            </Dragger>
          )}
        </Form.Item>
        <Form.Item label='Slug'>
          {getFieldDecorator('slug_th', { initialValue: initialValue.slug_th })(<Input disabled />)}
        </Form.Item>
        <Form.Item label='ชื่อ'>
          {getFieldDecorator('title_th', {
            rules: [{ required: true, message: 'บังคับใส่ชื่อหมวด!' }],
            initialValue: initialValue.title_th
          })(<Input onChange={(e) => setFieldsValue({ slug_th: genSlug(e.target.value) })} />)}
        </Form.Item>
        <Form.Item label='Description'>
          {getFieldDecorator('description_th', {
            rules: [{ required: true, message: 'บังคับใส่คำอธิบายหมวด!' }],
            initialValue: initialValue.description_th
          })(<Editor height={250} />)}
        </Form.Item>

        <Form.Item label='Meta Keyword'>
          {getFieldDecorator('meta_keyword', { initialValue: initialValue.meta_keyword })(<Input />)}
        </Form.Item>
        <Form.Item label='Meta Description'>
          {getFieldDecorator('meta_description', { initialValue: initialValue.meta_description })(<Input />)}
        </Form.Item>
        <Form.Item wrapperCol={{ span: 12, offset: 5 }}>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Form.Item>
      </Form>
    )
  }
}

export default Form.create()(CategoriesForm)
