import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { getPersistor } from '@rematch/persist'
import { PersistGate } from 'redux-persist/lib/integration/react'

import App from './App'
import store from './createStore'

import './index.css'

// import * as serviceWorker from './serviceWorker';
const persistor = getPersistor()

const Root = (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
)
ReactDOM.render(Root, document.getElementById('root'))
