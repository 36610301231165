import Resource from '../utils/resource'

export const UserAPI = new Resource('/appUsers', {
  getUserProfile: {
    url: 'me',
    method: 'get'
  },
  login: {
    url: 'login',
    method: 'post'
  }
})
