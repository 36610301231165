import React from 'react'
import { Editor } from '@tinymce/tinymce-react'
import _config from '../../utils/config'

const apiURL = _config.API_URL

class NEditor extends React.Component {
  render() {
    const { value, onChange, onBlur, height = 500 } = this.props
    return (
      <Editor
        apiKey='uv1z9133gcnqqobrmoasuo0lnc439c2saoe9gp6reefpge18'
        initialValue={value}
        init={{
          height,
          menubar: false,
          images_upload_url: `${apiURL}/editors/upload`,
          automatic_uploads: true,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste help wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | code | \
          link | image | media | \
          alignleft aligncenter alignright alignjustify | fullscreen | \
          bullist numlist outdent indent | removeformat | help'
        }}
        onEditorChange={onChange}
        onBlur={onBlur}
      />
    )
  }
}

export default NEditor
