import React from 'react'
import { withRouter } from 'react-router-dom'
import { message } from 'antd'
import Layout from '../../components/Layout'
import AdsForm from '../../components/AdsForm'
import { AdsAPI, ProvincesAPI, DistrictsAPI, SubdistrictsAPI } from '../../services'

class AdsCreate extends React.Component {
  state = {
    provincesList: [],
    districtsList: [],
    subdistrictsList: []
  };

  async componentDidMount () {
    const province = await ProvincesAPI.find({ filter: { order: 'name_th' } })
    this.setState({
      provincesList: province
    })
  }

  selectProvince = async (provinceCode) => {
    if (!provinceCode) return null
    const resDistrict = await DistrictsAPI.find({
      filter: { where: { province_code: provinceCode } }
    })
    this.setState({
      districtsList: resDistrict
    })
  }

  selectDistricts = async (districtCode) => {
    if (!districtCode) return null
    const resSubistrict = await SubdistrictsAPI.find({
      filter: { where: { district_code: districtCode } }
    })
    this.setState({
      subdistrictsList: resSubistrict
    })
  }

  handleSubmit = async data => {
    try {
      await AdsAPI.create(data)
      message.success('บันทึกแบนเนอร์สำเร็จ')
      this.props.history.push('/ads')
    } catch (e) {
      message.error('บันทึกแบนเนอร์ผิดพลาด')
      console.error('Error ==>>', e)
    }
  };

  render () {
    return (
      <Layout>
        <AdsForm
          title='เพิ่ม Ads'
          onSubmit={this.handleSubmit}
          // provincesList={this.state.provincesList}
          // districtsList={this.state.districtsList}
          // subdistrictsList={this.state.subdistrictsList}
          // selectProvince={this.selectProvince}
          // selectDistricts={this.selectDistricts}
        />
      </Layout>
    )
  }
}

export default withRouter(AdsCreate)
