import React from 'react'
import { Button, Icon } from 'antd'
import _isEqual from 'lodash/isEqual'
import _isEmpty from 'lodash/isEmpty'

import NeighborSelect from '../NeighborSelect'

class NeighborSelectList extends React.Component {
  state = {
    value: []
  }

  componentDidMount () {
    this.setState({
      value: this.props.value
    })
  }

  componentDidUpdate (prevProps, prevState) {
    if (!_isEqual(prevState.value, this.props.value)) {
      if (_isEmpty(this.props.value)) {
        return
      }
      this.setState({
        value: this.props.value
      })
    }
  }

  addMoreNeighbor = () => {
    const { value } = this.state

    value.push({
      id: Date.now(),
      province: undefined,
      code: undefined
    })

    this.setState({
      value
    })
  }

  remove = (index) => {
    const { value } = this.state
    value.splice(index, 1)
    this.setState({
      value
    })
  }

  render () {
    const { selectNeighbor, selectNeighborProvince, fetchNeighbor, neighborhoodsList } = this.props
    const { value } = this.state

    return (
      <>
        {
          value.map((item, index) => (
            <div key={item.id}>
              <NeighborSelect
                fetchNeighbor={fetchNeighbor}
                neighborhoodsList={neighborhoodsList}
                selectNeighbor={selectNeighbor}
                selectNeighborProvince={selectNeighborProvince}
                index={index}
                data={item}
              />
              <Icon
                className='dynamic-delete-button'
                type='minus-circle-o'
                onClick={() => this.remove(index)}
              />
            </div>
          ))
        }
        <Button type='dashed' onClick={this.addMoreNeighbor} style={{ width: '60%' }}>
          <Icon type='plus' /> เพิ่มย่าน
        </Button>
      </>
    )
  }
}

export default NeighborSelectList
