import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Select, Cascader, DatePicker, Spin, Switch } from 'antd'
import _ from 'lodash'
import _get from 'lodash/get'
import _debounce from 'lodash/debounce'
import _unionWith from 'lodash/unionWith'
import _isEqual from 'lodash/isEqual'
import _isNull from 'lodash/isNull'
import moment from 'moment'
import Upload from '../Upload'
import Editor from '../Editor'
import NeighborSelectList from '../NeighborSelectList'
import ProvinceSelectList from '../ProvinceSelectList'
import {
  CategoryAPI,
  ProjectAPI,
  ProvincesAPI,
  DistrictsAPI,
  SubdistrictsAPI,
  NeighborhoodsAPI,
  ArticleAPI
} from '../../services'

const { Option } = Select

class CreateForm extends React.Component {
  state = {
    allProjectsList: [],
    allDistrictsList: [],
    allSubdistrictsList: [],
    allNeighborhoodsList: [],
    categoriesTree: [],
    categoriesList: [],
    projectsList: [],
    provincesList: [],
    districtsList: [],
    subdistrictsList: [],
    neighborhoodsList: [],
    loading: false,
    switchStatus: false,
    allRedirectPath: []
  }

  componentDidMount() {
    this.fetch()
  }

  fetch = async () => {
    try {
      const categoriesTree = await CategoryAPI.tree()
      const categoriesList = await CategoryAPI.find()
      const provincesList = await ProvincesAPI.find()
      this.searchProject('')
      this.setState({
        categoriesList,
        provincesList,
        categoriesTree
      })

      if (this.props.data && this.props.data.status === 1) {
        this.setState({
          switchStatus: true
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  fetchProvince = async (provinceCode) => {
    if (!provinceCode) return null
    const districtsList = await DistrictsAPI.find({
      filter: { where: { province_code: provinceCode } }
    })

    const { allDistrictsList } = this.state
    const result = _unionWith(allDistrictsList, districtsList, _isEqual)
    this.setState({
      districtsList,
      allDistrictsList: result
    })
  }

  fetchDistrict = async (districtCode) => {
    if (!districtCode) return null
    const subdistrictsList = await SubdistrictsAPI.find({
      filter: { where: { district_code: districtCode } }
    })
    const { allSubdistrictsList } = this.state
    const result = _unionWith(allSubdistrictsList, subdistrictsList, _isEqual)

    this.setState({
      subdistrictsList,
      allSubdistrictsList: result
    })
  }

  fetchNeighbor = async (value) => {
    const neighborhoodsList = await NeighborhoodsAPI.find({
      filter: {
        where: {
          province_id: value
        }
      }
    })

    const { allNeighborhoodsList } = this.state
    const result = _unionWith(allNeighborhoodsList, neighborhoodsList, _isEqual)

    this.setState({
      neighborhoodsList,
      allNeighborhoodsList: result
    })
  }

  genSlug = (text = '') => {
    if (typeof text !== 'string') {
      return null
    }
    const charMap = { '&': ' and ', '%': ' percent ', '©': '(c)', '®': '(r)', '฿': ' baht ', $: ' dollar ' }
    const reg = /[^\w\s*_~()'"!:@ก-๛]+/gim
    let slug = text.split('').reduce((result, ch) => {
      return result + (charMap[ch] || ch).replace(reg, ' ')
    })
    slug = slug.trim().replace(new RegExp('[\\s]+', 'gmi'), '-').toLowerCase()
    return slug
  }

  formatData = (values) => {
    const oldData = this.props.data
    const {
      allProjectsList = [],
      categoriesList = [],
      provincesList = [],
      allDistrictsList = [],
      allSubdistrictsList = [],
      allNeighborhoodsList = []
    } = this.state

    const projectsSelected =
      _get(values, 'projects', []).map((project) => {
        const result = allProjectsList.find((ap) => ap.id === project.key)
        if (result) {
          return result
        }
        const oldSelected = oldData.project.find((od) => od.id === project.key)
        return oldSelected
      }) || []
    // const categorySelected = categoriesList.find((category) => category.id === _get(values, 'category[0]'))
    // const subcategorySelected = _get(categorySelected, 'children', []).find((category) => category.id === _get(values, 'category[1]'))
    const category = categoriesList.filter((i) => _get(values, 'category', []).includes(i.id))
    const data = {
      project: projectsSelected.map((projectSelect) => ({
        id: projectSelect.id,
        title_en: projectSelect.title_en,
        title_th: projectSelect.title_th,
        code: projectSelect.project_code
      })),
      category: _.sortBy(category, 'level'),
      // category: [
      //   {
      //     id: _get(categorySelected, 'id'),
      //     title: {
      //       title_th: _get(categorySelected, 'title_th'),
      //       title_en: _get(categorySelected, 'title_en'),
      //     },
      //     alias: {
      //       alias_th: _get(categorySelected, 'slug_th'),
      //       alias_en: _get(categorySelected, 'slug_en'),
      //     },
      //     sub_categories: [
      //       {
      //         id: _get(subcategorySelected, 'id'),
      //         title: {
      //           title_th: _get(subcategorySelected, 'title_th'),
      //           title_en: _get(subcategorySelected, 'title_en'),
      //         },
      //         alias: {
      //           alias_th: _get(subcategorySelected, 'slug_th'),
      //           alias_en: _get(subcategorySelected, 'slug_en'),
      //         },
      //       },
      //     ],
      //   },
      // ],
      images: _get(values, 'images', []).map((image) => ({
        url: image.url,
        thumbnail: image.thumbnail,
        title: image.name
      })),
      files: _get(values, 'files', []).map((file) => ({
        url: file.url,
        description: file.name
      })),
      url: {
        alias_th: `${this.genSlug(_get(values, 'alias') || _get(values, 'title'), { lower: true, locale: 'th' })}`,
        alias_en: `${this.genSlug(_get(values, 'alias') || _get(values, 'title'), { lower: true, local: 'th' })}`
      },
      // id,
      date_article: moment(_get(values, 'date_article')).unix(),
      data_source: _get(values, 'data_source'),
      province: _get(values, 'province', []).map((address) => {
        let province_id = _get(address, 'province_id', null)
        province_id = _isNull(province_id) ? null : province_id.toString()

        let district_id = _get(address, 'district_id', null)
        district_id = _isNull(district_id) ? null : district_id.toString()

        let subdistrict_id = _get(address, 'subdistrict_id', null)
        subdistrict_id = _isNull(subdistrict_id) ? null : subdistrict_id.toString()

        const province = provincesList.find((p) => p.province_code === province_id)
        const district = allDistrictsList.find((d) => d.district_code === district_id)
        const subdistrict = allSubdistrictsList.find((s) => s.sub_district_code === subdistrict_id)
        return {
          district_id: parseInt(_get(address, 'district_id'), 10),
          district_th: _get(district, 'name_th'),
          district_en: _get(district, 'name_en'),
          province_id: parseInt(_get(address, 'province_id'), 10),
          province_th: _get(province, 'name_th'),
          province_en: _get(province, 'name_en'),
          subdistrict_id: parseInt(_get(address, 'subdistrict_id'), 10),
          subdistrict_th: _get(subdistrict, 'name_th'),
          subdistrict_en: _get(subdistrict, 'name_en')
        }
      }),
      neighbor: _get(values, 'neighbor', []).map((neigh) => {
        const province = provincesList.find((p) => p.province_code === neigh.province_id)
        const neighbor = allNeighborhoodsList.find((n) => n.code === neigh.neighbor_id)

        return {
          province_id: _get(province, 'province_code'),
          province_th: _get(neighbor, 'province_name_th'),
          province_en: _get(neighbor, 'province_name_en'),
          neighbor_id: _get(neighbor, 'code'),
          neighbor_th: _get(neighbor, 'name_th'),
          neighbor_en: _get(neighbor, 'name_en')
        }
      }),
      body: _get(values, 'body', '<p></p>'),
      metatag: {
        keywords: _get(values, 'meta_title'),
        description: _get(values, 'meta_description')
      },
      recommend: _get(oldData, 'recommend', [{}, {}, {}, {}]),
      language: 'th',
      title: _get(values, 'title').trim(),
      alias: this.genSlug(_get(values, 'alias', '').trim(), { lower: true, locale: 'th' }),
      status: _get(values, 'status') ? 1 : 0
    }
    return data
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        try {
          const { id } = this.props.match.params
          const result = this.formatData(values)
          this.props.onSubmit({ data: result, id })
        } catch (error) {
          console.log('Article Form Error', error)
        }
      }
    })
  }

  onUpload = (field, value) => {
    this.props.form.setFieldsValue({ [field]: value })
  }

  searchProject = async (value) => {
    this.setState(
      {
        loading: true
      },
      async () => {
        const projectsList = await ProjectAPI.find({
          filter: {
            where: {
              title_th: { regexp: `/.*${value}.*/i` }
            },
            limit: 10
          }
        })
        const { allProjectsList } = this.state
        const result = _unionWith(allProjectsList, projectsList, _isEqual)

        this.setState({
          projectsList,
          loading: false,
          allProjectsList: result
        })
      }
    )
  }

  selectProvinces = async (index, provinceCode) => {
    const province = this.props.form.getFieldValue('province')
    if (!province[index]) {
      province.push({
        id: Date.now(),
        province_id: provinceCode,
        district_id: undefined,
        subdistrict_id: undefined
      })
    } else {
      province[index].province_id = provinceCode
      province[index].district_id = undefined
      province[index].subdistrict_id = undefined
    }
    this.props.form.setFieldsValue({ province })
  }

  selectDistricts = async (index, districtCode) => {
    const province = this.props.form.getFieldValue('province')
    if (province[index]) {
      province[index].district_id = districtCode
      province[index].subdistrict_id = undefined
      this.props.form.setFieldsValue({ province })
    }
  }

  selectSubdistricts = async (index, subdistrictCode) => {
    const province = this.props.form.getFieldValue('province')
    if (province[index]) {
      province[index].subdistrict_id = subdistrictCode
      this.props.form.setFieldsValue({ province })
    }
  }

  selectNeighborProvince = (index, value) => {
    const neighborValue = this.props.form.getFieldValue('neighbor')
    if (!neighborValue[index]) {
      neighborValue.push({
        id: Date.now(),
        province_id: value,
        neighbor_id: undefined
      })
    } else {
      neighborValue[index].province_id = value
      neighborValue[index].neighbor_id = undefined
    }
    this.props.form.setFieldsValue({ neighbor: neighborValue })
  }

  selectNeighbor = (index, value) => {
    const neighborValue = this.props.form.getFieldValue('neighbor')
    if (neighborValue[index]) {
      neighborValue[index].neighbor_id = value
      this.props.form.setFieldsValue({ neighbor: neighborValue })
    }
  }

  fetchRedirect = async () => {
    const result = await ArticleAPI.allredirect({
      source: `/article/${_get(this.props, 'data.alias')}-${this.props.keyId}`
    })
    this.setState({ allRedirectPath: result })
  }

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 }
      }
    }

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 8
        }
      }
    }
    const { data } = this.props
    const { getFieldDecorator } = this.props.form
    const { projectsList } = this.state
    return (
      <div>
        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
          <Form.Item label='ชื่อเรื่อง'>
            {getFieldDecorator('title', {
              initialValue: _get(data, 'title'),
              rules: [
                {
                  required: true,
                  message: 'กรุณากรอกชื่อเรื่อง'
                }
              ]
            })(
              <Input
                placeholder='ชื่อเรื่อง'
                onChange={(e) => this.props.form.setFieldsValue({ alias: e.target.value })}
              />
            )}
          </Form.Item>
          <Form.Item label='alias'>
            {getFieldDecorator('alias', {
              initialValue: _get(data, 'alias'),
              rules: [
                {
                  required: true,
                  message: 'กรุณากรอกข้อความเพื่อไปทำ URL ของบทความ'
                }
              ]
            })(<Input placeholder='ชื่อเรื่อง' />)}
          </Form.Item>
          {this.props.data && this.props.keyId && (
            <Form.Item label='ดู redirect path ท้ังหมด'>
              <Button onClick={this.fetchRedirect}>Fetch ข้อมูล</Button>
              {this.state.allRedirectPath.map((i) => {
                return <p>{i.path}</p>
              })}
            </Form.Item>
          )}
          <Form.Item label='หมวด'>
            {getFieldDecorator('category', {
              // initialValue: _get(data, 'category[0].id') && [_get(data, 'category[0].id'), _get(data, 'category[0].sub_categories[0].id')],
              initialValue: _.sortBy((_get(data, 'category') || []), 'level').map((i) => i.id),
              rules: [
                {
                  required: true,
                  message: 'กรุณาเลือกหมวดหมู่'
                }
              ]
            })(
              <Cascader
                fieldNames={{ label: 'title_th', value: 'id' }}
                options={this.state.categoriesTree}
                placeholder='Please select'
              />
            )}
          </Form.Item>
          <Form.Item label='วันที่บทความ'>
            {getFieldDecorator('date_article', {
              initialValue: moment.unix(_get(data, 'date_article', moment().unix()))
            })(<DatePicker format='DD/MM/YYYY' />)}
          </Form.Item>

          <Form.Item label='แหล่งอ้างอิง'>
            {getFieldDecorator('data_source', {
              initialValue: _get(data, 'data_source')
            })(<Input placeholder='แหล่งอ้างอิง' />)}
          </Form.Item>

          <Form.Item label='เกี่ยวข้องกับพื้นที่ (ถ้ามี)'>
            {getFieldDecorator('province', {
              initialValue: _get(data, 'province', [])
            })(
              <ProvinceSelectList
                provincesList={this.state.provincesList}
                districtsList={this.state.districtsList}
                subdistrictsList={this.state.subdistrictsList}
                fetchProvince={this.fetchProvince}
                fetchDistrict={this.fetchDistrict}
                selectProvinces={this.selectProvinces}
                selectDistricts={this.selectDistricts}
                selectSubdistricts={this.selectSubdistricts}
              />
            )}
          </Form.Item>

          <Form.Item label='เกี่ยวข้องกับย่าน (ถ้ามี)'>
            {getFieldDecorator('neighbor', {
              initialValue: _get(data, 'neighbor', [])
            })(
              <NeighborSelectList
                fetchNeighbor={this.fetchNeighbor}
                neighborhoodsList={this.state.neighborhoodsList}
                selectNeighbor={this.selectNeighbor}
                selectNeighborProvince={this.selectNeighborProvince}
              />
            )}
          </Form.Item>

          <Form.Item label='เกี่ยวข้องกับโครงการ (ถ้ามี) '>
            {getFieldDecorator('projects', {
              initialValue: _get(data, 'project', []).map((project) => ({
                key: project.id,
                label: project.title_th
              }))
            })(
              <Select
                showSearch
                mode='multiple'
                style={{ width: 250 }}
                labelInValue
                fieldNames={{ label: 'title_th', value: 'id' }}
                notFoundContent={this.state.loading ? <Spin size='small' /> : null}
                placeholder='เลือกโปรเจค'
                onSearch={_debounce(this.searchProject, 300)}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {projectsList.map((item) => (
                  <Option value={item.id}>{item.title_th}</Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item label='รูปต่างๆ'>
            {getFieldDecorator('images', {
              getValueFromEvent: this.onUpload
            })(
              <Upload
                listType='picture-card'
                folderName='article'
                accept='.png, .jpg, .jpeg, gif, .svg'
                defaultFileList={_get(data, 'images', [])}
                onUpload={(value) => this.onUpload('images', value)}
              />
            )}
          </Form.Item>

          <Form.Item label='ไฟล์เอกสาร'>
            {getFieldDecorator('files', {
              getValueFromEvent: this.onUpload
            })(
              <Upload
                listType='picture'
                folderName='file'
                button
                defaultFileList={_get(data, 'files', [])}
                onUpload={(value) => this.onUpload('files', value)}
              />
            )}
          </Form.Item>
          <Form.Item label='ข้อความ'>
            {getFieldDecorator('body', {
              initialValue: _get(data, 'body', '<p></p>')
            })(<Editor />)}
          </Form.Item>

          <Form.Item label='Meta Keyword'>
            {getFieldDecorator('meta_title', {
              initialValue: _get(data, 'metatag.keywords')
            })(<Input placeholder='Meta title' />)}
          </Form.Item>

          <Form.Item label='Meta Description'>
            {getFieldDecorator('meta_description', {
              initialValue: _get(data, 'metatag.description')
            })(<Input placeholder='Meta description' />)}
          </Form.Item>

          <Form.Item label='เผยแพร่'>
            {getFieldDecorator('status', {
              initialValue: _get(data, 'status'),
              valuePropName: 'checked'
            })(<Switch defaultChecked={this.state.switchStatus} />)}
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            <Button type='primary' htmlType='submit'>
              บันทึกบทความ
            </Button>
          </Form.Item>
        </Form>
      </div>
    )
  }
}
const ArticleCreateForm = Form.create()(CreateForm)
export default withRouter(ArticleCreateForm)
