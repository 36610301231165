import React from 'react'
import { Select } from 'antd'
import _get from 'lodash/get'

const { Option } = Select

class ProvinceSelect extends React.PureComponent {
  async componentDidMount () {
    if (_get(this, 'props.data.province_id')) {
      this.props.fetchProvince(this.props.data.province_id)
    }
    if (_get(this, 'props.data.district_id')) {
      this.props.fetchDistrict(this.props.data.district_id)
    }
  }

  selectProvince = (provinceCode) => {
    this.props.fetchProvince(provinceCode)
    this.props.selectProvinces(this.props.index, provinceCode)
  }

  selectDistrict = (districtCode) => {
    this.props.fetchDistrict(districtCode)
    this.props.selectDistricts(this.props.index, districtCode)
  }

  selectSubdistrict = (subdistrictCode) => {
    this.forceUpdate()
    this.props.selectSubdistricts(this.props.index, subdistrictCode)
  }

  render () {
    const { subdistrictsList, provincesList, districtsList, data } = this.props
    return (
      <>
        <Select
          showSearch
          value={(data.province_id) ? data.province_id.toString() : ''}
          style={{ width: 200 }}
          onChange={this.selectProvince}
          placeholder='เลือกจังหวัด'
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {
            provincesList.map(item => <Option key={item.province_code} value={item.province_code}>{item.name_th}</Option>)
          }
        </Select>
        <Select
          showSearch
          value={(data.district_id) ? data.district_id.toString() : ''}
          style={{ width: 200 }}
          onChange={this.selectDistrict}
          placeholder='เลือกอำเภอ'
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {
            districtsList.map(item => <Option key={item.district_code} value={item.district_code}>{item.name_th}</Option>)
          }
        </Select>
        <Select
          showSearch
          value={(data.subdistrict_id) ? data.subdistrict_id.toString() : ''}
          style={{ width: 200 }}
          placeholder='เลือกตำบล'
          onChange={this.selectSubdistrict}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {
            subdistrictsList.map(item => <Option key={item.sub_district_code} value={item.sub_district_code}>{item.name_th}</Option>)
          }
        </Select>
      </>
    )
  }
}

export default ProvinceSelect
