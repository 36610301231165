import React from 'react'
import { Form, Input, Button, Row, Col, Select } from 'antd'
import { Link } from 'react-router-dom'

import Layout from '../../components/Layout'
import AdsTable from '../../components/AdsTable'
import { AdsAPI } from '../../services'
const { Option } = Select

class Ads extends React.Component {
  state = {
    data: [],
    pagination: {},
    loading: false
  };

  componentDidMount () {
    this.fetch()
  }

  fetch = async (params = {}) => {
    const fillters = {
      'data.title': { like: params.banner || '' }
    }
    if (params.banner_location) (fillters.position = params.banner_location)
    const result = await AdsAPI.find({
      filter: {
        where: fillters
      }
    })
    const allData = await AdsAPI.count({
      where: fillters
    })
    const pagination = { ...this.state.pagination }
    pagination.total = allData.count
    this.setState({
      data: result,
      pagination
    })
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    pager.current = pagination.current
    this.setState({
      pagination: pager
    })
    this.fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    })
  };

  handleSearch = e => {
    e.preventDefault()
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        try {
          this.fetch(values)
        } catch (error) {
          console.log('AdsForm Error', error)
        }
      }
    })
  };

  clear=() => {
    this.props.form.setFieldsValue(
      {
        banner: '',
        banner_location: ''
      }
    )
  }

  render () {
    const { getFieldDecorator } = this.props.form
    return (
      <Layout>
        <div>
          <div style={{ textAlign: 'right' }}>
            <Link to='/ads/create'>
              <Button type='primary'> เพิ่ม Ads ....</Button>
            </Link>
          </div>
          <br />
          <div>
            <Form onSubmit={this.handleSearch}>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item label='ชื่อแบนเนอร์'>
                    {getFieldDecorator(
                      'banner',
                      {}
                    )(<Input placeholder='ชื่อแบนเนอร์' />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label='ตำแหน่งการแสดง'>
                    {getFieldDecorator(
                      'banner_location',
                      {}
                    )(
                      <Select placeholder='เลือกตำแหน่งแบนเนอร์'>
                        <Option value=''>ทั้งหมด</Option>
                        <Option value='A1'>A1: หน้าแรก ตำแหน่งบนสุด</Option>
                        <Option value='A2'>A2: หน้าแรก ในกล่องโครงการแนะนำ</Option>
                        <Option value='A3'>A3: Banner about service of baania</Option>
                        <Option value='B1'>B1: ด้านขวา ของหน้ารายการข้อมูล</Option>
                        <Option value='C1'>C1: หน้ารายละเอียดบ้าน, ในตำแหน่งด้านขวาของข้อมูลบ้าน</Option>
                        <Option value='C2'>C2: หน้ารายละเอียดบ้าน, ในตำแหน่งด้านขวาของสถานที่รอบข้าง</Option>
                        <Option value='C3'>C3: หน้ารายละเอียดบ้าน, ในตำแหน่งด้านล่าง Loan Calculator</Option>
                        <Option value='C4'>C4: หน้ารายละเอียดบ้าน, ในตำแหน่งด้านขวาของกราฟประวัติราคา</Option>
                        <Option value='D1'>D1: หน้า Baania Article, ในตำแหน่งด้านขวาของเนื้อหา</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24} style={{ textAlign: 'right' }}>
                  <Form.Item>
                    <Button
                      type='primary'
                      htmlType='submit'
                      style={{ marginRight: 8 }}
                    >
                      Search
                    </Button>
                    <Button type='primary' onClick={this.clear}>Clear</Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
        <div className='search-result-list'>
          <AdsTable
            data={this.state.data}
            pagination={this.state.pagination}
            handleTableChange={this.handleTableChange}
            fetchData={this.fetch}
          />
        </div>
      </Layout>
    )
  }
}
const antAds = Form.create()(Ads)
export default antAds
